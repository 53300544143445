// import "./src/css/style.css";
// import CacheBuster from "./src/components/cacheBuster.js";

// export const onClientEntry = () => {
//   console.log("CLIENT ENTRY")
// }
export const registerServiceWorker = () => true;

// exports.onServiceWorkerUpdateFound = () => window.location.reload(true);

// export const wrapRootElement = ({ element }) => (
//   <CacheBuster>
//      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
//        if (loading) return null
//        if (!loading && !isLatestVersion) {
//          // You can decide how and when you want to force reload
//          refreshCacheAndReload()
//        }
//        return <AppProvider>{element}</AppProvider>
//      }}
//    </CacheBuster>
//  )