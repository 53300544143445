// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-lunar-explorer-js": () => import("./../../src/pages/lunar-explorer.js" /* webpackChunkName: "component---src-pages-lunar-explorer-js" */),
  "component---src-pages-news-js": () => import("./../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-story-js": () => import("./../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-our-team-js": () => import("./../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-our-vision-js": () => import("./../../src/pages/our-vision.js" /* webpackChunkName: "component---src-pages-our-vision-js" */),
  "component---src-pages-partners-js": () => import("./../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */)
}

